<template>
  <div>
    <client-view
        ref="clientView"
    />
    <el-dialog
        :fullscreen="true"
        v-model="surrogateLoginViewVisible"
        style="padding: 0px"
    >

      <iframe src="" id="surrogateLoginFrame" style="width: 100%; height: 90vh" referrerpolicy="no-referrer"/>
    </el-dialog>
    <el-row>
      <el-col :span="24">
        <el-card>
          <el-form label-position="top" style="margin-bottom: 20px">
            <el-row :gutter="20">
              <el-col :md="12" :xs="24">
                <el-form-item label="Поиск" size="small">
                  <el-input v-model="filter.freeSearchToken" clearable/>
                </el-form-item>
              </el-col>
              <el-col :md="12" :xs="24">
                <TimeSearch
                    :full-width-select="true"
                    :free-date-time="true"
                    :month="false"
                    :short-date="false"
                    :free-date-label="'Период регистрации'"
                    ref="registeredAfFilter"
                    v-model="filter.regTime"
                    :small="true"
                />
                <!--                <el-form-item label="Менеджер">-->
                <!--                  <el-input v-model="filter.manager"/>-->
                <!--                </el-form-item>-->
                <!--                <el-form-item label="Статус">-->
                <!--                  <el-input v-model="filter.status"/>-->
                <!--                </el-form-item>-->

              </el-col>

            </el-row>
            <el-button type="success" plain @click="filterUsers" :loading="dataLoading" style="margin-top: 20px">
              Найти
            </el-button>
            <el-button type="default" plain @click="resetFilters" :loading="dataLoading" style="margin-top: 20px">
              Сбросить
            </el-button>
          </el-form>
          <el-row>
            <el-col :span="24">
              <TableSpinner :loading="dataLoading" :rows="10">
                <el-table
                    :data="visibleClients"
                    style="width: 100%"
                >
                  <el-table-column label="ID" min-width="70" prop="system_id">
                    <template #default="scope">
                      <el-button text class="table-text-button blue-button" @click="showUser(scope.row.system_id)">
                        {{ scope.row.system_id }}
                      </el-button>
                    </template>
                  </el-table-column>
                  <el-table-column label="Тип" min-width="50">
                    <template #default="scope">
                      <font-awesome-icon icon="suitcase" v-if="scope.row.isIndividual"></font-awesome-icon>
                      <font-awesome-icon icon="user" v-else></font-awesome-icon>
                      <span class="circle danger" v-if="scope.row.active === 0"></span>
                    </template>
                  </el-table-column>
                  <el-table-column label="Менеджер" min-width="140" prop="manager"/>
                  <el-table-column label="Название" min-width="300" prop="userName"/>
                  <el-table-column label="Договор" min-width="200">
                    <template #default="scope">
                      <el-tag type="success" v-if="scope.row.hasContract">Загружен</el-tag>
                      <el-tag type="danger" v-else>Не загружен</el-tag>
                      <br/>
                      <el-tag style="margin-top: 5px" :type="scope.row.autoregSuccess ? 'success' : 'danger'">
                        {{ scope.row.autoregStatus }}
                      </el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column label="Баланс" min-width="150" prop="balance"/>
                  <el-table-column label="Дата регистрации" min-width="120" prop="registeredAt"/>
                  <el-table-column label="Дата последней авторизации" min-width="120" prop="lastAuthAt"/>
                  <el-table-column label="" min-width="100" fixed="right">
                    <template #default="scope">
                      <el-button text class="table-text-button blue-button" @click="clientLogin(scope.row)">В кабинет
                      </el-button>
                    </template>
                  </el-table-column>

                </el-table>
                <div class="solo-pagination-container">
                  <el-dropdown split-button type="primary" trigger="click" :disabled="exportEmailsLoading">
                    Экспорт емейлов
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item @click="exportClientEmails('activeOnly')">Только активные клиенты</el-dropdown-item>
                        <el-dropdown-item @click="exportClientEmails('all')">Все клиенты</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                  <el-pagination
                      background
                      layout="sizes, prev, pager, next"
                      :total="clients.length"
                      class="wide-page-size"
                      :page-sizes="[10, 20, 50]"
                      :page-size="pageSize"
                      :pager-count="3"
                      :hide-on-single-page="true"
                      @size-change="handlePageSizeChange"
                      @current-change="handleCurrentPageChange"
                  >
                  </el-pagination>
                </div>
              </TableSpinner>

            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import TimeSearch from "@shared/components/reports/time-search.component"
import moment from "moment"
import ClientView from "../../components/clients/client-view.component.vue";

export default {
  name: "AllClients",
  title: "Мои клиенты",
  components: {ClientView, TimeSearch},
  mounted() {
    this.refreshUsers()
  },
  methods: {
    refreshUsers() {
      this.dataLoading = true
      this.axios.get("/admin/clients/getAll")
          .then(async resp => {
            this.rawClients = resp.data.users
            this.managers = resp.data.managers
            await this.filterUsers()
          })
    },
    handlePageSizeChange(newSize) {
      this.pageSize = newSize
    },
    handleCurrentPageChange(newPage) {
      this.currentPage = newPage
    },
    async filterUsers() {
      this.dataLoading = true
      await this.$nextTick()
      this.filteredUsers = this.rawClients.filter(c => {
        if (!this.filter.freeSearchToken && !this.filter.manager && !this.filter.status && !this.filter.regTime.from && !this.filter.regTime.to) return true
        if (this.filter.regTime.from && c.registeredAtNum < this.filter.regTime.from.valueOf()) return false
        if (this.filter.regTime.to && c.registeredAtNum > this.filter.regTime.to.valueOf()) return false
        if (this.filter.freeSearchToken) {
          if (
              !(new RegExp(this.filter.freeSearchToken.trim(), "gi")).test(c.system_id) &&
              !(new RegExp(this.filter.freeSearchToken.trim(), "gi")).test(patchPhone(c.info.phone)) &&
              !(new RegExp(this.filter.freeSearchToken.trim(), "gi")).test(c.info.email) &&
              !(new RegExp(this.filter.freeSearchToken.trim(), "gi")).test(c.userName) &&
              !(new RegExp(this.filter.freeSearchToken.trim(), "gi")).test(c.info.inn)
          )
            return false
        }
        return true
      })
      await this.$nextTick()
      this.currentPage = 1
      this.dataLoading = false
    },
    async resetFilters() {
      this.dataLoading = true
      await this.$nextTick()
      this.filter.freeSearchToken = ""
      this.filter.manager = ""
      this.filter.status = ""
      this.$refs.registeredAfFilter.reset()
      await this.filterUsers()
    },
    async showUser(systemId) {
      await this.$refs.clientView.show(systemId)
    },
    clientLogin(user) {
      this.axios.get("/admin/clients/surrogateLogin", {params: {systemId: user.system_id}})
          .then(async resp => {
            window.open(`${process.env.VUE_APP_CLIENT_UI_URL}login?token=${resp.data.token}&surrogate=true`, '_blank')
            // this.surrogateLoginViewVisible = true
            // await this.$nextTick()
            // document.getElementById('surrogateLoginFrame').setAttribute("src", `${process.env.VUE_APP_CLIENT_UI_URL}login?token=${resp.data.token}&surrogate=true`)
          })
    },
    exportClientEmails(type){
      this.exportEmailsLoading = true
      this.axios.get("/admin/support/allClientEmails", {params: {type}})
          .then(resp => {
            this.exportEmailsLoading = false
            let csvContent = resp.data.emails || []
            let blob = new Blob(csvContent.map(s => s + "\n"), {type: 'text/csv'});
            let a = document.createElement('a');
            a.download = `емейлы_клиентов_техно_${moment().format("DD_MM_YYYY_HH_MM")}_${type === 'all' ? 'все' : 'только_активные'}.csv`;
            a.href = URL.createObjectURL(blob);
            a.click();
          })


    }
  },
  computed: {
    clients() {
      // console.log("Redraw")
      // console.time("Clients process")


      let mapped = this.filteredUsers.map((c, i) => {

        let autoregStatus = ''
        if (String(c.autoregistered) === '0') autoregStatus = 'Зарегистрирован изнутри'
        else {
          if (String(c.autoregistered_status) === '1') autoregStatus = 'Подтвержден'
          if (String(c.autoregistered_status) === '0') autoregStatus = 'Не подтвержден'
          if (String(c.autoregistered_status) === '-1') autoregStatus = 'Не рассмотрен'
        }
        return {
          system_id: c.system_id,
          isIndividual: c.isIndividual,
          manager: `${c.manager.info.lastname || ''} ${(c.manager.info.firstname || '').charAt(0)}. ${(c.manager.info.patronymic || '').charAt(0)}.`,
          userName: c.userName,
          hasContract: c.hasContract,
          autoregSuccess: String(c.autoregistered) === '0' || String(c.autoregistered_status) === '1',
          autoregStatus,
          registeredAt: c.registeredAt,//moment(c.registered).format("DD.MM.YYYY HH:mm:ss"),
          lastAuthAt: c.lastAuthAt,//moment(c.authorized).format("DD.MM.YYYY HH:mm:ss"),
          balance: c.balance,
          // raw: c,
          active: c.active
        }
      })
      // console.timeEnd("Clients process")
      return mapped
    },
    visibleClients() {
      return this.clients.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
    }
  },
  data() {
    return {
      filter: {
        freeSearchToken: "",
        manager: "",
        status: "",
        regTime: {from: '', to: ''}

      },
      rawClients: [],
      filteredUsers: [],
      managers: [],
      dataLoading: false,
      pageSize: 10,
      currentPage: 1,
      surrogateLoginViewVisible: false,
      exportEmailsLoading: false
    }
  },
}
</script>

<style scoped>

</style>