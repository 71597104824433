<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-tabs v-model="visiblePanel" class="primary-heading-tabs no-top-padding-tabs">
          <el-tab-pane label="Общий отчет" name="common">
            <CommonTable ref="commonTable">
              <template v-slot:admin-settings="{onUsersChange, onSenderTypeChange}">
                <el-col :md="12" :xs="24">
                  <el-form label-position="top">
                    <el-form-item label="Пользователи">
                      <client-select v-model="filterUsers" @change="onUsersChange" :multiple="true"/>
<!--                      <el-select-v2 v-model="filterUsers" multiple filterable clearable @change="onUsersChange"-->
<!--                                    class="full-width" :options="$store.getters.clients.map(c => {return {label: c.system_id, value: c.system_id}})">-->
<!--                      </el-select-v2>-->
<!--                      <el-select v-model="filterUsers" multiple filterable clearable @change="onUsersChange"-->
<!--                                 class="full-width">-->
<!--                        <el-option value="" label="Все"/>-->
<!--                        <el-option v-for="client in $store.getters.clients" :value="client.system_id"-->
<!--                                   :label="client.system_id"/>-->
<!--                      </el-select>-->
                    </el-form-item>
                    <el-form-item label="Тип подписи">
                      <el-select v-model="filterSenderType" @change="onSenderTypeChange" class="full-width">
                        <el-option value="" label="Все"/>
                        <el-option value="classic" label="Общие"/>
                        <el-option value="international" label="Международные"/>
                      </el-select>
                    </el-form-item>
                  </el-form>
                </el-col>
              </template>
            </CommonTable>
          </el-tab-pane>
          <el-tab-pane label="Подробный отчет" name="detail">
            <DetailTable :is-call-view="false" ref="detailTable">

              <template v-slot:admin-message-id>
                <el-table-column label="ID" width="130">
                  <template #default="scope">
                    {{scope.row._id}}
                  </template>
                </el-table-column>
              </template>
              <template v-slot:admin-settings="{onUsersChange, onSenderTypeChange}">
                  <el-form label-position="top">
                    <el-form-item label="Пользователи">
                      <client-select v-model="filterUsers" @change="onUsersChange" :multiple="true"/>
<!--                      <el-select-v2 v-model="filterUsers" multiple filterable clearable @change="onUsersChange"-->
<!--                                    class="full-width" :options="$store.getters.clients.map(c => {return {label: c.system_id, value: c.system_id}})">-->
<!--                      </el-select-v2>-->
<!--                      <el-select v-model="filterUsers" multiple filterable clearable @change="onUsersChange"-->
<!--                                 class="full-width">-->
<!--                        <el-option value="" label="Все"/>-->
<!--                        <el-option v-for="client in $store.getters.clients" :value="client.system_id"-->
<!--                                   :label="client.system_id"/>-->
<!--                      </el-select>-->
                    </el-form-item>
                    <el-form-item label="Тип подписи">
                      <el-select v-model="filterSenderType" @change="onSenderTypeChange" class="full-width">
                        <el-option value="" label="Все"/>
                        <el-option value="classic" label="Общие"/>
                        <el-option value="international" label="Международные"/>
                      </el-select>
                    </el-form-item>
                  </el-form>
              </template>
            </DetailTable>
          </el-tab-pane>
          <el-tab-pane label="Статистика" name="stats">
            <SentMessagesStatistics
                :is-admin-view="true"
                :all-agregators="allAgregators"
                :all-managers="allManagers"
            >
              <template
                  v-slot:admin-settings-user-select="{onUsersChange, onManagersChange}"
              >
                <el-form label-position="top">
                  <el-form-item label="Клиент"  size="small">
                    <client-select v-model="filterUsers" @change="onUsersChange" :multiple="true"/>
<!--                    <el-select-v2 v-model="filterUsers" multiple filterable clearable @change="onUsersChange"-->
<!--                               class="full-width" :options="$store.getters.clients.map(c => {return {label: c.system_id, value: c.system_id}})">-->
<!--                    </el-select-v2>-->
                  </el-form-item>
                  <el-form-item label="Менеджер" size="small">
                    <el-select v-model="statsFilters.managers" multiple filterable clearable @change="onManagersChange"
                               class="full-width">
                      <el-option v-for="n in allManagers" :value="n.system_id" :label="`${n.system_id}  ${n.info.patronymic} ${n.info.firstname} ${n.info.lastname}`"/>
                    </el-select>
                  </el-form-item>
                </el-form>
              </template>
              <template
                  v-slot:admin-settings-1="{
                onSenderChange, onOperatorsChange, onOperatorsGroupsChange, onCountriesChange
              }"
              >
                <el-form label-position="top" >
                  <el-form-item label="Отправитель" size="small">
                    <el-select-v2 v-model="statsFilters.sender" multiple filterable clearable allow-create @change="onSenderChange"
                                 :options="allNames"
                               class="full-width">
                    </el-select-v2>
                  </el-form-item>
                  <el-form-item label="Оператор" size="small">
                    <el-select v-model="statsFilters.operators" multiple filterable clearable @change="onOperatorsChange"
                               class="full-width">
                      <el-option v-for="n in Array.from($store.getters.operators.reduce((acc, o) => {acc.add(o.OrgName); return acc}, new Set()))" :value="n" :label="n"/>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Группа операторов" size="small">
                    <el-select v-model="statsFilters.operatorsGroups" multiple filterable clearable @change="onOperatorsGroupsChange"
                               class="full-width">
                      <el-option v-for="n in $store.getters.operatorsGroups" :value="n._id" :label="n.title"/>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Страна" size="small">
                    <el-select v-model="statsFilters.countries" multiple filterable clearable @change="onCountriesChange"
                               class="full-width">
                      <el-option v-for="n in $store.getters.countries" :value="String(n.country_code)" :label="n.country_name"/>
                    </el-select>
                  </el-form-item>


                </el-form>
              </template>
              <template
                  v-slot:admin-settings-2="{
                onAgregatorChange, onTrafficTypeChange, onCallTypeChange
              }"
              >

                  <el-form-item label="Канал" size="small">
                    <el-select v-model="statsFilters.agregators" multiple filterable clearable
                               @change="onAgregatorChange"
                               class="full-width">
                      <el-option v-for="n in allAgregators" :value="n._id" :label="n.name"/>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Тип трафика" size="small">
                    <el-select v-model="statsFilters.trafficTypes" multiple filterable clearable
                               @change="onTrafficTypeChange"
                               class="full-width">
                      <el-option value="" label="Мультиподпись"></el-option>
                      <el-option value="add" label="Рекламные (без шаблона)"></el-option>
                      <el-option label="Рекламные (с шаблоном)" value="templated_add"/>
                      <el-option value="service" label="Сервисные СМС"></el-option>
                      <el-option value="informational" label="Информационные СМС"></el-option>
                      <el-option value="transactional" label="Транзакционные СМС"></el-option>
                      <el-option value="authorisation" label="Авторизационные СМС"></el-option>
                      <el-option value="international" label="Международные отправители"></el-option>
                    </el-select>
                  </el-form-item>
                <el-form-item label="Тип звонка" size="small">
                  <el-select v-model="statsFilters.callTypes" multiple filterable clearable
                             @change="onCallTypeChange"
                             class="full-width">
                    <el-option value="flash" label="Флешколл"></el-option>
                    <el-option value="voiceCode" label="Voice code"></el-option>
                    <el-option value="voicePassword" label="Голосовое сообщение (произв. текст)"></el-option>

                  </el-select>
                </el-form-item>

              </template>

            </SentMessagesStatistics>
          </el-tab-pane>

        </el-tabs>

      </el-col>
    </el-row>
  </div>
</template>

<script>


import CommonTable from "@shared/components/reports/common-sent-messages-table.component"
import DetailTable from "@shared/components/reports/detail-sent-messages-table.component"
import SentMessagesStatistics from "@shared/components/reports/statistics.component.vue";
import ClientSelect from "../../components/layout/client-select.component.vue";

export default {
  name: "SentMessages",
  title: "Отправленные сообщения",
  components: {
    ClientSelect,
    SentMessagesStatistics,
    CommonTable,
    DetailTable
  },
  watch: {
    visiblePanel() {
      this.filterUsers = []
      this.filterSenderType = ""
      if (this.$refs.commonTable) this.$refs.commonTable.resetAdminFilter()
      if (this.$refs.detailTable) this.$refs.detailTable.resetAdminFilter()
    }
  },
  mounted() {
    this.axios.get("/admin/support/allActiveNames").then(resp => this.allNames = (resp.data || []).map(n => {return {label: n, value: n}}))
    this.axios.get("/admin/routing/allAgregators").then(resp => this.allAgregators = resp.data || [])
    this.axios.get("/user/getMySubusers").then(resp => this.allManagers = (resp.data || []))
  },
  computed: {},
  methods: {

  },
  data() {
    return {
      visiblePanel: "common",
      filterUsers: [],
      filterSenderType: "",

      allNames: [],
      allAgregators: [],
      allManagers: [],
      statsFilters: {
        users: [],
        managers: [],
        sender: [],

        operators: [],
        operatorsGroups: [],
        countries: [],
        agregators: [],
        trafficTypes: [],
        callTypes: [],
      }
    }
  }
}

</script>