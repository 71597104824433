<template>
  <div>
    <el-dialog
        title="Добавить операторов в группу"
        v-model="addOperatorsModalVisible"
        class="modal-95-70-60"
    >
      <el-input placeholder="Поиск" class="full-width" v-model="filterOperatorsToAddToken" style="margin-bottom: 20px"/>
      <el-table :data="visibleUnassignedOperators" @selection-change="handleAddOperatorsSelectionChange" ref="addOperatorsTable">
        <el-table-column type="selection" width="55"/>
        <el-table-column label="Название" prop="OrgName" min-width="120"/>
        <el-table-column label="MCC" prop="mcc" min-width="80"/>
        <el-table-column label="MNC" prop="MNC" min-width="80"/>
      </el-table>
      <el-button type="success" @click="addOperatorsToGroup()">Добавить</el-button>
    </el-dialog>
    <el-dialog
        :title="formTitle"
        v-model="groupFormVisible"
        width="100%"
    >
      <el-form label-position="top" ref="groupForm" :model="groupForm">
        <el-row :gutter="60">
          <el-col :xs="24" :md="12">

            <el-form-item prop="_id" style="display: none">
              <el-input v-model="groupForm._id"/>
            </el-form-item>
            <el-form-item prop="name" label="Наименование">
              <el-input v-model="groupForm.name"/>
            </el-form-item>
            <el-form-item prop="type" label="Тип">
              <el-radio-group v-model="groupForm.type">
                <el-radio label="classic">Обычный</el-radio>
                <el-radio label="vk">VK/OK</el-radio>
                <el-radio label="telegram">Telegram</el-radio>
                <el-radio label="whatsapp">Whatsapp</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item>
              <el-button type="success" @click="saveGroup()" :loading="saving">Сохранить</el-button>
            </el-form-item>

            <el-divider class="blue-divider hidden-md-and-up"/>
          </el-col>
          <el-col :xs="24" :md="12">
            <el-form-item prop="operators">
              <div style="display: flex; justify-content: space-between; margin-bottom: 20px; width: 100%">
                <remove-button v-if="selectedGroupOperators.length" @confirm="removeSelectedOperatorsFromGroup()">
                  Удалить выбранные
                </remove-button>
                <el-button size="small" text class="text-button table-text-button" @click="showAddOperatorToGroupModal()">
                  Добавить операторов
                </el-button>
              </div>

              <el-table :data="groupForm.operators" @selection-change="handleSelectionChange" ref="groupOperatorsTable">
                <el-table-column type="selection" width="55"/>
                <el-table-column label="Название" prop="OrgName" min-width="120"/>
                <el-table-column label="MCC" prop="mcc" min-width="80"/>
                <el-table-column label="MNC" prop="MNC" min-width="80"/>
              </el-table>
            </el-form-item>

          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <el-form label-position="top" ref="searchForm" :model="search">
      <el-row :gutter="40" justify="space-between">
        <el-col :xs="24" :md="8">
          <el-form-item label="Поиск" prop="token">
            <el-input class="full-width" placeholder="" clearable
                      v-model="search.token"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :md="8">
          <el-form-item label="Тип">
            <el-select v-model="search.type" class="full-width">
              <el-option value="" label="Все"/>
              <el-option value="classic" label="Обычный"/>
              <el-option value="vk" label="VK/OK"/>
              <el-option value="telegram" label="Telegram"/>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :md="5">
          <el-form-item label="  ">
            <el-button type="success" @click="showAddEditForm(null)">Добавить группу</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-table :data="visibleGroups">
      <el-table-column label="Название" prop="title" min-width="200"/>
      <el-table-column label="MCC" :formatter="g => g.mccs.join(',')" min-width="80"/>
      <el-table-column label="Размер" prop="size" min-width="80"/>
      <el-table-column label="Тип" prop="type" min-width="80"/>
      <el-table-column label="" min-width="120">
        <template #default="scope">
          <el-button text class="table-text-button" size="small" @click="showAddEditForm(scope.row)"
                     style="margin-right: 10px">
            Изменить
          </el-button>
          <remove-button @confirm="removeGroup(scope.row)">Удалить</remove-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="solo-pagination-container">
      <el-pagination background layout="sizes, prev, pager, next" :total="filteredGroups.length" class="wide-page-size"
                     :page-sizes="[20, 50, 100]" :page-size="operPageSize" :pager-count="3" :hide-on-single-page="true"
                     @size-change="s => pageSize = s" @current-change="p => currentPage = p"></el-pagination>
    </div>

  </div>
</template>
<script>


import TimeSearch from "@shared/components/reports/time-search.component"
import moment from "moment"
import RemoveButton from "@shared/components/layout/removeButton.component.vue";

export default {
  name: "GroupsTableAndForm",
  components: {
    RemoveButton,
    TimeSearch
  },
  props: ["operators", "groups"],
  methods: {
    resetSearchForm() {
      this.search.token = ""
      this.search.group = ""
    },
    saveGroup() {
      this.saving = true
      this.axios.post("/admin/routing/saveOperatorsGroup", {data: this.groupForm})
          .then(resp => {
            this.saving = false
            this.$refs.groupForm.resetFields()
            this.groupFormVisible = false
            this.$emit("reload")
          })
    },
    removeGroup(group) {
      this.axios.delete("/admin/routing/removeGroup", {params: {groupId: group._id}})
          .then(() => {
            this.$emit("reload")
          })
    },
    showAddEditForm(group) {
      if(this.$refs.groupOperatorsTable)
        this.$refs.groupOperatorsTable.clearSelection()
      if (group) {
        this.groupForm._id = group._id
        this.groupForm.name = group.title
        this.groupForm.type = group.type || "classic"
        this.groupForm.operators = this.operators.filter(o => group.operators.includes(o._id))
      }else{
        this.groupForm._id = ""
        this.groupForm.name = ""
        this.groupForm.type =  "classic"
        this.groupForm.operators = []
      }
      this.groupFormVisible = true
    },
    handleSelectionChange(val) {
      this.selectedGroupOperators = val.map(o => o._id)
    },
    handleAddOperatorsSelectionChange(val){
      this.selectedGroupOperatorsToAdd = val.map(o => o._id)
    },
    removeSelectedOperatorsFromGroup() {
      let doVisual = () => {
        this.groupForm.operators = this.groupForm.operators.filter(go => !this.selectedGroupOperators.includes(go._id))
        this.selectedGroupOperators = []
        if(this.$refs.groupOperatorsTable)
          this.$refs.groupOperatorsTable.clearSelection()


      }
      if(!this.groupForm._id){
        doVisual()
      }else {
        this.axios.post("/admin/routing/removeOperatorsFromGroup", {
          groupId: this.groupForm._id,
          operators: this.selectedGroupOperators
        })
            .then(resp => {
              this.$emit("reload", true)
              doVisual()
            })
      }
    },
    showAddOperatorToGroupModal(){
      if(this.$refs.addOperatorsTable)
        this.$refs.addOperatorsTable.clearSelection()
      this.selectedGroupOperatorsToAdd = []
      this.filterOperatorsToAddToken = ""
      this.addOperatorsModalVisible = true
    },
    addOperatorsToGroup(){
      let doVisual = () => {
        this.groupForm.operators.push(...this.operators.filter(o => this.selectedGroupOperatorsToAdd.includes(o._id)))
        this.selectedGroupOperatorsToAdd = []
        this.addOperatorsModalVisible = false
        if(this.$refs.addOperatorsTable)
          this.$refs.addOperatorsTable.clearSelection()
      }
      if(!this.groupForm._id){
        doVisual()
      }else {
        this.axios.post("/admin/routing/addOperatorsToGroup", {
          groupId: this.groupForm._id,
          operators: this.selectedGroupOperatorsToAdd
        })
            .then(resp => {
              this.$emit("reload", true)
              doVisual()
            })
      }
    }
  },
  computed: {
    filteredGroups() {
      let searchRegex = null
      if (this.search.token.trim()) searchRegex = new RegExp(this.search.token.trim(), "gi")
      return this.groups.filter(g => {
        if (this.search.type && this.search.type !== g.type) return false
        if (searchRegex) {
          if (!searchRegex.test(g.title) && !searchRegex.test(g.mccs.join(','))) return false
          searchRegex.lastIndex = 0
        }
        return true
      })
    },
    visibleGroups() {
      return this.filteredGroups.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
    },
    formTitle() {
      return this.groupForm._id ? "Редактирование группы" : "Новая группа"
    },
    unassignedOperators() {
      return this.operators.filter(o => !o.group)
    },
    visibleUnassignedOperators(){
      let searchRegex = null
      if (this.filterOperatorsToAddToken.trim()) searchRegex = new RegExp(this.filterOperatorsToAddToken.trim(), "gi")
      return this.unassignedOperators.filter(g => {
        if (searchRegex) {
          if (!searchRegex.test(g.OrgName) && !searchRegex.test(g.mcc) && !searchRegex.test(g.MNC)) return false
          searchRegex.lastIndex = 0
        }
        return true
      })
    }
  },

  data() {
    return {
      pageSize: 50, currentPage: 1,
      groupFormVisible: false,
      addOperatorsModalVisible: false,
      saving: false,
      groupForm: {
        _id: "",
        name: "",
        type: "classic",
        operators: []
      },
      selectedGroupOperators: [],
      selectedGroupOperatorsToAdd: [],
      search: {
        token: "",
        type: ""
      },
      filterOperatorsToAddToken: ""
    }
  }
}

</script>